// import React, { Component } from "react";
import React, { useState, useEffect, Component, Suspense } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useParams,
	useLocation,
} from "react-router-dom";
import { Dimensions, View, Text, ActivityIndicator } from "react-native";
import { APP_COLOURS } from "./APP_VARS";

// Replace direct imports with lazy imports
const DevicePolicy = React.lazy(() => import("./OpenUrls/DevicePolicy"));
const PostApproval = React.lazy(() =>
	import("./Authed/ContentCalendar/PostApproval"),
);
const PerformanceReview = React.lazy(() =>
	import("./Authed/PerformanceReview/PerformanceReview"),
);
const Home = React.lazy(() => import("./Authed/Home"));
const Login = React.lazy(() => import("./Unauthed/Login"));
const HomeMobile = React.lazy(() => import("./Authed/HomeMobile"));
const CompanyDocuments = React.lazy(() =>
	import("./OpenUrls/CompanyDocuments"),
);
const LiveStream = React.lazy(() => import("./OpenUrls/STREAM"));
const ContentApproval = React.lazy(() =>
	import("./Authed/ContentCalendar/ContentApproval"),
);
const ProjectRouter = React.lazy(() =>
	import("./Authed/DailyPlanner/ProjectRouter"),
);
const AllPresentations = React.lazy(() =>
	import("./Authed/PresentationApp/AllPresentations"),
);
// import { ProposalUrl } from "./OpenUrls/Proposals/ProposalUrl";
// import { PresentationUrl } from "./OpenUrls/Presentations";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

function useQuery() {
	// WORKS FOR QURY URL
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResponsiveHome = () => {
	// State to hold screen width
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	let query = useQuery();
	const { page } = useParams(); // This hooks allows you to access the
	let project_id = query.get("pid");
	// Effect to listen for screen resize
	useEffect(() => {
		const handleResize = () => setScreenWidth(window.innerWidth);
		window.addEventListener("resize", handleResize);

		// Cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// Conditionally rendering based on screen width
	const renderComponent =
		screenWidth < 700 ? (
			<HomeMobile page={page} project_id={project_id} />
		) : (
			<Home page={page} project_id={project_id} />
		);

	return <div>{renderComponent}</div>;
};

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}

		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	render() {
		return (
			<Router>
				<Suspense
					fallback={
						<div
							style={{
								height: "100vh",
								width: "100vw",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: APP_COLOURS.BG,
							}}>
							{/* <img
								src='/skrum_menu.png'
								alt='Loading...'
								style={{
									width: 50,
									height: 50,
								}}
							/> */}
						</div>
					}>
					<Routes>
						<Route path='/login' element={<Login />} />
						{/* <Route
							path='/Home/:page/:project'
							element={
								this.state.screenWidth < 700 ? (
									<HomeMobile />
								) : (
									<Home />
								)
							}
						/> */}

						<Route
							path='/home/:page'
							element={
								this.state.screenWidth < 700 ? (
									<HomeMobile />
								) : (
									<ResponsiveHome />
								)
							}
						/>

						<Route
							path='/home/projects/:project_id'
							element={<ProjectRouter />}
						/>

						<Route
							path='/home/presentations'
							element={<AllPresentations />}
						/>

						<Route
							path='/postapproval/:postid/:token'
							element={<PostApproval />}
						/>

						<Route
							path='/caa/:contentid/:token'
							element={<ContentApproval />}
						/>

						<Route
							path='/performancereview/:surveyId/:token'
							element={<PerformanceReview />}
						/>

						<Route
							path='/livestream/:token'
							element={<LiveStream />}
						/>

						{/* 192.168.20.11:3000/postapproval/retinolhpr1/xV3-0Ea5T2eLbRJYo-eGoT0Dq_Lq9o1-kyGK5gg */}

						<Route
							path='/smreport/:brand/:token'
							element={<PerformanceReview />}
						/>

						<Route
							path='/doc/:documentId/:token'
							element={<CompanyDocuments />}
						/>

						{/* <Route
							path='/proposal/:proposal_id/:token'
							element={<ProposalUrl />}
						/>

						<Route
							path='/presentation/:presentation_id/:token'
							element={<PresentationUrl />}
						/> */}

						<Route path='*' element={<Navigate to='/login' />} />
					</Routes>
				</Suspense>
			</Router>
		);
	}
}

export default index;
